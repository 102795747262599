<template>
    <div>
        <h2><strong>关注公众号</strong></h2>
        <div class="box">
            <div class="payEwm">
                <div class="border_corner border_corner_left_top"></div>
                <div class="border_corner border_corner_right_top"></div>
                <div class="border_corner border_corner_left_bottom"></div>
                <div class="border_corner border_corner_right_bottom"></div>
                <div class="qr-code">
                    <vue-qr :text="text" :logoSrc='logoSrc' :logoScale="0.2" :size="600"></vue-qr>
                </div>
            </div>
            <div>
                <p>宣易官方服务号，关注即注册。</p>
                <p>海量模板，简易制作、方便宣传。</p>
            </div>
        </div>
    </div>
</template>
<script>
import VueQr from "vue-qr";

export default {
  components: {
    VueQr,
  },

  computed: {},
  data() {
    return {
      text: "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=Mzg2ODUzMTg1Mg==&scene=110#wechat_redirect",
      logoSrc:require("../../assets/icon.svg")
    };
  },

};
</script>
<style lang="less" scoped>
h2{
    border-bottom: 1px solid #ddd;
    padding-bottom:20px;
    strong{
        position: relative;
        &:after {
            content: '';
            position: absolute;
            height: 3px;
            width: 120px;
            left: 0;
            bottom: -25px;
            background-image: linear-gradient(to right, #0049ee , #36bcf0);
    
        }
    }
}
.box{
    display: flex;
    flex-direction: column;
    align-content: space-around;
    flex-wrap: wrap;
    text-align: center;
}
</style>
<style lang="less" scoped>
.main {
  width: 100%;
  height: 550px;
  border: 2px solid #1c93cb;
  border-radius: 4px;
}
.border_corner {
  z-index: 2500;
  position: absolute;
  width: 24px;
  height: 24px;
  background: rgba(0, 0, 0, 0);
  border: 3px solid #999999;
}
.border_corner_left_top {
  top: -2px;
  left: -2px;
  border-right: none;
  border-bottom: none;
  border-top-left-radius: 4px;
}
.border_corner_right_top {
  top: -2px;
  right: -3px;
  border-left: none;
  border-bottom: none;
  border-top-right-radius: 4px;
}
.border_corner_left_bottom {
  bottom: -1px;
  left: -2px;
  border-right: none;
  border-top: none;
  border-bottom-left-radius: 4px;
}
.border_corner_right_bottom {
  bottom: -2px;
  right: -3px;
  border-left: none;
  border-top: none;
  border-bottom-right-radius: 4px;
}
h2 {
  font-weight: bold;
  margin-bottom: 20px;
}
.Price {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  line-height: 0;
  .payNum {
    color: #fb582d;
    font-size: 30px;
    font-weight: bold;
    margin-left: 8px;
    span {
      font-size: 14px;
      margin-right: 4px;
    }
  }
}
.payEwm {
  border: 1px solid #e5e5e5;
  position: relative;
  margin: 30px auto;
  // padding: 14px;
  width: 230px;
  height: 230px;
  .qr-code {
    width: 100%;
    img {
      width: 100%;
    }
  }
}
.remarks {
  display: flex;
  justify-content: center;
  color: #000;
}
</style>